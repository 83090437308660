import React from 'react';
import TextPage, { TextPageSection } from '../templates/text-page';

const TakeAction = () => (
  <TextPage title="Take action">
    <TextPageSection title="Demand action">
      <p>
        Use the hashtag
        {' '}
        <a
          href="https://twitter.com/intent/tweet?hashtags=thecolouroftheclimatecrisis"
          target="_blank"
          rel="noreferrer"
        >
          #thecolouroftheclimatecrisis

        </a>
        {' '}
        and help us demand that politicians centre the
        experiences, ideas and leadership of Black people, Indigenous people and
        other people of colour in the fight against climate change.
      </p>
    </TextPageSection>
    <TextPageSection title="Learn and do more">
      <p>
        The work in this exhibition is a response to a
        {' '}
        <a
          href="https://dothegreenthing.com/issue/the-colour-of-the-climate-crisis/"
          target="_blank"
          rel="noreferrer"
        >
          Do The Green Thing

        </a>
        {' '}
        article on the connections between white supremacy and climate change by
        Minnie Rahman.
      </p>
      <p>
        Minnie ends her piece with guidance on how to be a good ally, and
        suggests environmental organisations like
        {' '}
        <a
          href="https://www.instagram.com/wretchedotearth/?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Wretched of the Earth

        </a>
        ,
        {' '}
        <a
          href="https://healingjusticeldn.org/"
          target="_blank"
          rel="noreferrer"
        >
          Healing Justice Collective
        </a>
        {' '}
        and
        {' '}
        <a
          href="https://www.greennewdealuk.org/"
          target="_blank"
          rel="noreferrer"
        >
          Green New Deal UK

        </a>
        {' '}
        for people of colour and allies to support.
      </p>
    </TextPageSection>
  </TextPage>
);

export default TakeAction;
