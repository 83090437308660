import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import './text-page.scss';

interface TextPageProps {
  children: React.ReactNode
  title: string
  className?: string
}

const TEXT_PAGE_CLASS = 'text-page';

const TextPage = ({ children, title, className } : TextPageProps) => (
  <Layout>
    <SEO title={`${title} - The Colour of the Climate Crisis`} />
    <div className={[TEXT_PAGE_CLASS].concat(className.split(' ')).join(' ')}>
      <h1 className="text-page-title">{title}</h1>
      <div className="text-page-content">
        { children }
      </div>
    </div>
  </Layout>
);

export const TextPageSection = ({ children, title, className } : TextPageProps) => (
  <section
    className={['text-page-section'].concat(className.split(' ')).join(' ')}
  >
    <h2 className="text-page-section-title">
      {title}
    </h2>
    <div className="text-page-section-text">
      {children}
    </div>
  </section>
);

TextPage.defaultProps = {
  className: '',
};

TextPageSection.defaultProps = TextPage.defaultProps;

export default TextPage;
